import { createSlice } from '@reduxjs/toolkit';
import { TAB_ID_STATUS_STORE } from 'pages/store/components/TabStatusStore';

const initialState = {
  showWithDraw: false,
  loading: false,
  loadingExport: false,
  listDepot: [],
  dataImportWareHouse: {},
  pageIndex: 0,
  billDetails: [],
  loadingBill: false,
  statistic: {},
  openSearchBill: false,
  showRight: false,
  filter: {
    statusStore: "",
    pageSize: 20,
    getCount: true,
    status: 0,
    search: '',
    depot: 0,
    dateTimeOption: 0,
    fromDate: undefined,
    toDate: undefined,
    empId: 0,
    isGetAll: false
  },
};

const importWareHouseSlice = createSlice({
  name: 'importWareHouse',
  initialState,
  reducers: {
    onChangeWithDraw(state, action) {
      state.showWithDraw = action.payload;
    },
    onChangeShowRight(state, action) {
      state.showRight = action.payload;
    },
    onChangeOpenSearchBill(state, action) {
      state.openSearchBill = action.payload;
    },
    changePageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    onChangeFilter(state, actions) {
      state.filter = { ...state.filter, ...actions?.payload };
    },
    changeStatusStore(state, actions) {
      state.filter = { ...initialState.filter, statusStore: actions?.payload };
    },
    getListImportWareHouse(state) {
      state.loading = true;
    },
    getListImportWareHouseSuccess(state, action) {
      state.loading = false;
      state.dataImportWareHouse = { ...state.dataImportWareHouse, ...action.payload };
    },
    getListImportWareHouseFailed(state) {
      state.loading = false;
    },
    getListDepot(state) {
      state.loading = true;
    },
    getListDepotSuccess(state, action) {
      state.listDepot = action.payload;
      state.loading = false;
    },
    getListDepotFailed(state) {
      state.loading = false;
    },

    exportImportWareHouse(state) {
      state.loadingExport = true;
    },
    exportImportWareHouseSuccess(state, action) {
      state.loadingExport = false;
    },
    exportImportWareHouseFailed(state) {
      state.loadingExport = false;
    },
    getBillDetail(state) {
      state.loadingBill = true;
    },
    getBillDetailSuccess(state, action) {
      state.loadingBill = false;
      state.billDetails = action.payload;
    },
    getBillDetailFailed(state) {
      state.loadingBill = false;
    },
    getStatistic(state, action) {
      state.statistic = {
        ...action.payload,
        Total: state.filter.status ? state.statistic.Total : action.payload.Total,
        TotalKg: state.filter.status ? state.statistic.TotalKg : action.payload.TotalKg,
        TotalStatus: action.payload.Total,
        TotalKgStatus: action.payload.TotalKg,
      };
    },
    clearDepot(state) {
      state.listDepot = [];
    },
    clearBillDetail(state) {
      state.billDetails = [];
    },
    clearImportWareHouse(state) {
      state.dataImportWareHouse = {};
    },
    clearFilter(state) {
      state.filter = initialState.filter;
    },
    clearData: () => initialState,
  },
});

// Actions
export const importWareHouseActions = importWareHouseSlice.actions;

// Reducer
const importWareHouseReducer = importWareHouseSlice.reducer;
export default importWareHouseReducer;
