import SvgIcon from 'components/common/SvgIcon';
import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/commonSlice';
import { CustomRangeStyled } from './styled';
import { t } from 'i18next';

const Heading = ({ date, changeMonth, onNext }) => (
  <nav className="calendar-header">
    <div className="calendar-header-date-wrapper" onClick={onNext}>
      <div className="calendar-header-date">
        {t('user:month')} {date?.format('M')} - {date?.format('YYYY')}
      </div>
      <SvgIcon name="down_calendar" className="calendar-header-icon-change-year" />
    </div>
    <div className="calendar-header-icon-wrapper">
      <SvgIcon
        onClick={() => changeMonth(date.month() - 1)}
        name="prev"
        className="calendar-header-icon"
      />

      <SvgIcon
        onClick={() => changeMonth(date.month() + 1)}
        name="prev"
        className="calendar-header-icon calendar-header-icon__next"
      />
    </div>
  </nav>
);

const Day = ({ currentDate, date, startDate, endDate, onClick }) => {
  let className = ['date-item-wrapper'];

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }

  return (
    <span onClick={() => onClick(date)} currentDate={date} className={className.join(' ')}>
      <div className="date-item">{date.date()}</div>
    </span>
  );
};

const LIST_LABEL_DAY = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const Days = ({ date, startDate, endDate, onClick }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextsMonth = moment(date).add(1, 'month');
  let days = [];
  let labels = LIST_LABEL_DAY?.map((i, idx) => (
    <span className="calendar-week-name" key={idx}>
      {i}
    </span>
  ));

  // for (let i = 1; i <= 7; i++) {
  //   const label = moment().day(i).format('ddd');
  //   labels.push(
  //     <span className="calendar-week-name" key={label}>
  //       {label}
  //     </span>
  //   );
  // }

  for (let i = firstDayDate.day(); i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    days.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    days.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  const daysCount = days.length;
  for (let i = 1; i <= 42 - daysCount; i++) {
    nextsMonth.date(i);
    days.push(
      <Day
        key={moment(nextsMonth).format('DD MM YYYY')}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(nextsMonth)}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  return (
    <nav className="calendar--days">
      {labels.concat()}
      {days.concat()}
    </nav>
  );
};

const CustomRangeCalendar = ({ onNext, onConfirm, range, onClose }) => {
  const { t } = useTranslation();
  const {
    date = moment(),
    startDate = moment(),
    endDate = moment(),
  } = useSelector((state) => state?.common?.rangeCalendar);
  const dispatch = useDispatch();

  const setDate = (newDate) => dispatch(commonActions.onChangeRangeCalendar({ date: newDate }));

  // const resetDate = () => setDate(moment());

  const changeMonth = (month) => setDate(date?.month(month));

  const changeDate = (date) => {
    if (!range)
      return dispatch(commonActions.onChangeRangeCalendar({ startDate: date, endDate: date }));
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (
      newStartDate === null ||
      date.isBefore(newStartDate, 'day') ||
      !newStartDate.isSame(newEndDate, 'day')
    ) {
      newStartDate = moment(date);
      newEndDate = moment(date);
    } else if (date.isSame(newStartDate, 'day') && date.isSame(newEndDate, 'day')) {
      newStartDate = null;
      newEndDate = null;
    } else if (date.isAfter(newStartDate, 'day')) {
      newEndDate = moment(date);
    }
    dispatch(commonActions.onChangeRangeCalendar({ startDate: newStartDate, endDate: newEndDate }));
  };

  return (
    <CustomRangeStyled>
      <div className="modern-calendar-note">
        {t('common:calendar-note-can-select-multiple')}

        <SvgIcon name="x" className="modern-calendar-icon-close" onClick={onClose} />
      </div>
      <div className="calendar">
        <Heading date={date} changeMonth={changeMonth} onNext={onNext} />

        <Days onClick={changeDate} date={date} startDate={startDate} endDate={endDate} />
        <div className="calendar-export" onClick={() => onConfirm?.({ startDate, endDate })}>
          {t('common:confirm')}
        </div>
      </div>
    </CustomRangeStyled>
  );
};

export default memo(CustomRangeCalendar);
