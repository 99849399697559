import Services from 'services';

// ACCOUNT
const STATUS = [null, true, false];

const getListAccount = async (body) =>
  await Services.post('/api/admin/Account/getList', { ...body, status: STATUS[+body?.status] });
const getDetailAccount = async (params) =>
  await Services.get('/api/admin/Account/getDetail', { params });
const deleteAccount = async (params) =>
  await Services.post('/api/admin/Account/delete', '', { params });
const createUpdateAccount = async (body) =>
  await Services.post('/api/admin/Account/createUpdate', body);
const updatePositionUser = async (body) =>
  await Services.post('/api/admin/Account/updatePositionUser', body);

// CLIENT

const getListClient = async (body) => await Services.post('/api/admin/Client/getListClient', body);
const deleteClient = async (params) =>
  await Services.post('/api/admin/Client/delete', '', { params });
const getDetailClient = async (params) =>
  await Services.get('/api/admin/Client/getDetail', { params });
const createUpdateClient = async (body) =>
  await Services.post('/api/admin/Client/createUpdate', body);
const getEmployeeClient = async (params) =>
  await Services.get('/api/admin/Client/getEmployee', { params });

export {
  getListAccount,
  getDetailAccount,
  deleteAccount,
  createUpdateAccount,
  getListClient,
  deleteClient,
  getDetailClient,
  createUpdateClient,
  getEmployeeClient,
  updatePositionUser,
};
