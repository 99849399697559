import { all, call, put, takeLatest } from 'redux-saga/effects';
import { employeeActions } from 'redux/employeeSlice';
import { getEmployeeClient } from 'services/adminService';

function* getListEmployeeForUser(action) {
  try {
    const res = yield call(getEmployeeClient, action.payload);
    if (!res) return yield put(employeeActions.getListEmployeeForUserFailed());
    yield put(employeeActions.getListEmployeeForUserSuccess(res?.data));
  } catch (error) {
    yield put(employeeActions.getListEmployeeForUserFailed());
  }
}

export default function* employeeSaga() {
  yield all([takeLatest(employeeActions.getListEmployeeForUser.type, getListEmployeeForUser)]);
}
