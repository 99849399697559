import styled from 'styled-components';
import {
  StoreStyled,
  TabStatusStoreStyled,
  SearchStyled,
  SearchStatusStyled,
} from 'pages/store/styled';
import { AccountInfoStyled } from 'components/account-info/styled';

export const DropdownAffStyled = styled(SearchStyled)`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 !important;
  border-bottom-left-radius: 0px !important;
  gap: 0px !important;
  position: unset !important;
  background: unset !important;
  .aff-header {
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    color: rgba(93, 114, 133, 1);
  }
  .aff-content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 10px;
  }
  .list-aff {
    flex: 1;
    overflow: auto;
    padding-bottom: 10px;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

export const StaffStyled = styled(StoreStyled)`
  .right-panel {
    overflow: hidden;
  }
  .min-width-250 {
    min-width: 250px;
  }
  .min-width-400 {
    min-width: 400px;
  }

  .max-width-400 {
    max-width: 400px;
  }
  .max-width-500 {
    max-width: 500px;
    .text-style {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .column-cell {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .row-cell {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .phone {
    font-size: 14px;
    color: rgba(46, 64, 92, 0.7);
    font-weight: 600;
  }
  .icon-16 {
    display: flex;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .icon-check {
    display: flex;
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
      path {
        stroke: #28cd41;
      }
    }
  }
  .icon-social {
    width: 16px;
    height: 16px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const TabStaffStyled = styled(TabStatusStoreStyled)`
  .status_item_active .status_item_icon {
    svg ellipse {
      fill: #c020f3;
    }
  }
`;

export const SearchStaffStyled = styled(SearchStyled)`
  overflow: hidden;
`;
export const SearchStatusStaffStyled = styled(SearchStatusStyled)`
  .search-date-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-status {
    height: 100px;
    width: auto;
    object-fit: contain;
  }
  .img-staff {
    height: 87px;
    width: auto;
    object-fit: contain;
  }
  .status-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
  .text_label {
    color: #8d41ee;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .god {
    color: #fe8f01;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    span {
      color: #374151;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .status-content-view {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .text {
    color: #5c68f3;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .percent_icon {
    display: flex;
  }
  .percent {
    color: #2ac670;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const SearchListStyled = styled(SearchStatusStyled)`
  flex: 1;
  overflow: hidden;
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
  .h-56 {
    height: 56px;
  }
  .p-18-20 {
    padding: 18px 20px !important;
  }
  .search-date-wrapper {
    overflow: hidden;
  }
  .search-status-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .search-date-detail {
    flex: 1;
    overflow: auto;
    flex-direction: column;
    padding: 0;
  }
  .list-item {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 10px 30px;
    background-color: #f5f8fb;
    cursor: pointer;
    border-bottom: 1px solid #eceff1;
    &:last-child {
      border-bottom: unset;
    }
    .list-item__avatar {
    }
    .list-item__text {
      color: #5b73e8;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .list-item__check {
      display: flex;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #ff541a;
        }
      }
    }
  }
`;

export const DetailStyled = styled.div`
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: var(--right-panel-box-shadow);
  position: relative;
  .account-form-header-icon-x svg {
    width: 24px;
    height: 24px;
    path {
      stroke: rgba(123, 129, 144, 1);
    }
  }
  .ml--25 {
    margin-left: -25px;
  }
  .h-37 {
    height: 37px;
  }
  .disabled {
    pointer-events: none;
    cursor: not-allowed !important;
  }
  .header-customer-with-icon {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .ant-input-disabled {
    background-color: var(--account-info-prefix-background) !important;
    color: #2e405c !important;
  }

  .account-form-content {
    padding: 20px;
  }
  .save-staff {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .save-btn {
    align-self: flex-end;
    border-radius: 10px 0 10px 0;
    padding: 10px 30px;
    background-color: #ff541a;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
  }
  .save-customer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const AccountStyled = styled(AccountInfoStyled)`
  min-width: unset;
  width: unset;

  .icon-delete {
    background-color: rgba(217, 217, 217, 0.6);
    width: 32px;
    height: 32px;
    border-radius: 4px 0 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  .upload-item {
    width: 80px;
    height: 80px;
    background-color: #ebebeb;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #555b6d;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
  .upload-has-img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .not-allow {
    cursor: not-allowed;
  }
  .gtcn {
    color: #7b8190;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
  }

  .disabled .ant-input-password-icon {
    pointer-events: none;
  }
  .input-password {
    .ant-input-password-icon {
      svg {
        display: flex;
        cursor: pointer;
        path {
          fill: #7b8190;
        }
      }
    }
    input {
      text-transform: unset;
    }
  }
  .permission-content-right-view {
    background-color: #fff;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .ant-input-suffix {
    font-size: 14px;
    font-weight: 600;
    color: #ff541a;
  }
  .account-form {
    height: 100% !important;
  }

  .justify-content-between {
  }

  .permission input {
    &::placeholder {
      color: #2e405c !important;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
    }
  }
  .ant-input-number-affix-wrapper {
    width: 100%;
    padding: 0;
    border: var(--account-info-form-border) !important;
    box-shadow: unset !important;
    background-color: var(--account-input-background);
    overflow: hidden;
    border-radius: 5px;
    height: 37px;
    box-sizing: content-box;
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number-prefix {
      height: 37px;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      color: var(--account-info-form-input);
      text-transform: uppercase;
      padding: 10px;
      background-color: var(--account-info-prefix-background);
      border-right: var(--account-info-prefix-border-right);
      margin: 0;
    }
    input {
      font-size: 14px;
      padding: 8px 11px;
      font-weight: 600;
      color: #2e405c !important;
      &::placeholder {
        color: #2e405c !important;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .ant-input-number-suffix {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      padding: 8px;
      position: relative;
      color: #ff541a;
    }
  }
  .ant-input-number-affix-wrapper-disabled {
    background-color: var(--account-info-prefix-background) !important;
  }
  .flex-1 {
    flex: 1;
  }
  .underline .ant-input-number-suffix {
    text-decoration: underline;
  }
  .img-upload {
    height: 80px;
    width: 80px;
    object-fit: fill;
    border-radius: 6px;
  }
  .img-uploaded {
    background-color: red;
    pointer-events: none;
  }
  .position-setting {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .change-position {
    display: flex;
    svg {
      path {
        fill: #ff541a;
      }
    }
  }
`;

export const PermissionStyled = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  .permission-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .close-icon {
    display: flex;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: #ff541a;
      }
    }
  }
  .permission-content {
    display: flex;
    height: 475px;
  }
  .permission-content-left {
    height: 100%;
    padding: 20px;
    flex: 1;
    gap: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .permission-content-right {
    background-color: #eff2f4;
    padding: 20px 0 0 20px;
    flex: 1;
  }
  .permission-content-right-view {
    background-color: #fff;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .right-title {
    font-size: 26px;
    font-weight: 600;
    color: #374151;
    text-transform: uppercase;
  }
  .category-item {
    border-radius: 5px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 20px;
    &__active {
      background-color: #e9f5fe;
      .category-icon-down {
        rotate: -90deg;
      }
    }
  }
  .category-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .category-title {
    color: #2e405c;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    flex: 1;
  }
  .category-icon-down {
    display: flex;
  }
  .ant-tree-treenode {
    width: 100%;
    position: relative;
  }
  .ant-tree-title {
    color: #2e405c;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .parent-title {
    color: #2e405c;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .ant-tree-switcher {
    right: 0px;
    z-index: 2;
    position: absolute;
    width: 92%;
    text-align: end;
    &:hover {
      background-color: transparent !important;
    }
  }

  .ant-tree-node-selected,
  .ant-tree-checkbox + span:hover {
    background-color: transparent !important;
  }
  .ant-tree-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .ant-tree {
    flex: 1;
    overflow: hidden auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .footer-permission {
    display: flex;
    justify-content: flex-end;
    .btn-save {
      padding: 10px 30px;
      border-radius: 5px;
      background-color: #ff541a;
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .avatar-permission {
    border-radius: 16px;
    border: 4px solid #eceff1;
    margin-left: 10px;
  }
`;

export const SettingDepartmentStyled = styled.div`
  padding: 20px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  max-height: 700px;
  overflow: hidden;
  display: flex;
  .ant-checkbox-wrapper {
    overflow: hidden;
    span {
      overflow: hidden;
    }
    .ant-checkbox {
      width: 20px;
      min-width: 20px;
    }
  }
  .btn-save-dept {
    display: block;
    align-self: flex-end;
    background: #ff541a;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
  .ant-spin-nested-loading {
    flex: 1;
    overflow: hidden;
  }
  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .setting-dep-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header-icon {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .header-title {
    font-size: 26px;
    font-weight: 600;
    color: #5d7285;
    text-transform: uppercase;
  }
  .close-icon {
    display: flex;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: red;
      }
    }
  }
  .list-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #fe8f01;
      }
    }
  }
  .list-dep {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .input-add-new {
    border-color: #dbdfe2 !important;
    box-shadow: unset;
    border-radius: 5px;
    padding: 0px !important;
    overflow: hidden;
    input {
      &::placeholder {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
      }
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }
    .ant-input-suffix {
      cursor: pointer;
      padding: 0 15px;
      background-color: #f7f8fb;
      border-left: 1px solid #dbdfe2;

      .add-new-btn {
        color: #191919;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .dept-item {
    background-color: #f5f8fb;
    border-radius: 5px;
  }
  .show {
    .icon-right {
      rotate: -90deg;
    }
    .dept-item-content {
      display: flex;
    }
  }
  .dept-item-header {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    input {
      border-color: transparent;
      box-shadow: unset;
      font-size: 20px;
      font-weight: 600;
      color: #374151;
      padding: 2px 11px !important;
    }
    .icon-left,
    .icon-right {
      display: flex;
    }
    .header-title {
      flex: 1;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: #374151;
    }
  }
  .dept-item-content {
    display: none;
    padding: 15px 20px;
    flex-direction: column;
    gap: 20px;

    .dept-input-add-new {
      border-color: #dbdfe2 !important;
      box-shadow: unset;
      border-radius: 5px;
      padding: 0px !important;
      overflow: hidden;
      input {
        &::placeholder {
          color: #7b8190;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
      .ant-input-suffix {
        cursor: pointer;
        padding: 0 15px;
        background-color: #f7f8fb;
        border-left: 1px solid #dbdfe2;

        .btn-add-new {
          color: #ff4000;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
  }
  .list-position {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* max-height: 250px;
    overflow: auto; */
    .position-item {
      display: flex;
      align-items: center;
      gap: 10px;
      input {
        height: 34px;
        border: unset;
        box-shadow: unset;
        color: #374151;
        font-size: 16px;
        font-weight: 600;
      }
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
      .position-name {
        color: #374151;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
`;

export const ImagePreviewStyled = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .preview-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .preview-title {
    color: #2e405c;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
  }
  .preview-close {
    display: flex;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      rect {
        fill: #f3f6f9;
      }
      path {
        stroke: #ff541a;
      }
    }
  }
  .preview-content {
    display: flex;
    gap: 15px;
  }
  .image-selected-view {
    flex: 1;
    background-color: rgba(52, 55, 71, 0.15);
    padding: 10px;
    border-radius: 5px;
    height: 520px;
  }
  .image-view {
    height: 500px;
    width: 100%;
    object-fit: contain;
  }
  .list-image-preview {
    background-color: rgba(52, 55, 71, 0.15);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    height: 520px;
    gap: 10px;
    overflow: auto;
    width: 120px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .slick-slider,
  .slick-list {
    height: 440px !important;
  }
  .slick-slide {
    height: 110px !important;
    border: unset !important;
    border-color: unset !important;
    box-shadow: unset;
    outline: 0;
    div {
      border: unset !important;
    }
    img:focus {
      outline: none !important;
    }
  }
  .image-item {
    width: 100px;
    min-height: 100px;
    height: 100px;
    object-fit: fill;
    opacity: 0.7;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #eceff1;
    *:focus {
      outline: none;
    }
  }
  .isActive {
    opacity: 1;
    border: 2px solid #5c68f3 !important;
    *:focus {
      outline: none;
    }
  }
  .swipe-icon {
    display: flex;
    justify-content: center;
    cursor: pointer;
    &__down {
      rotate: 180deg;
    }
  }
`;
