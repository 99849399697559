import { Col, Dropdown, Row, Popover, Avatar } from 'antd';
import Men from 'assets/images/store/men.png';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon';
import CustomRangeCalendar from 'components/custom-range-calendar';
import { SELECT_DATE_OPTIONS } from 'constants/index';
import { useStatusTab } from 'hook/useStore';
import { numberWithCommas } from 'libs/stringsUtils';
import moment from 'moment';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/commonSlice';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import { SearchDateStyled } from '../styled';
import { getStorage } from 'libs/storage';
import { LANGUAGE } from 'constants/localStorage';
import { UserOutlined } from '@ant-design/icons';

export const Item = ({ title, icon, img, avatar, useAvatar }) => (
  <div className={'dropdown-select-item'}>
    {img ? <img alt="" className="dropdown-select-item-img" src={img} /> : null}

    {useAvatar ? (
      <Avatar className="avatar" size={24} shape="circle" icon={<UserOutlined />} src={avatar} />
    ) : null}
    {icon ? <SvgIcon name={icon} className="dropdown-select-icon-item" /> : null}
    <div className="dropdown-select-name">{title}</div>
    <SvgIcon name="delivered" className="dropdown-select-icon" />
  </div>
);

const SearchDate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IS_COMMISSION, IS_DELIVERED } = useStatusTab();
  const { filter, statistic: statisticWH } = useSelector((state) => state.importWareHouse);
  const { statistic: statisticDL } = useSelector((state) => state.delivered);
  const { dataCommission } = useSelector((state) => state.commission);
  const { userInfo } = useSelector((state) => state.user);
  const language = getStorage(LANGUAGE);

  const [openRangeCalendar, setOpenRangeCalendar] = useState(false);
  const { totalMoney } = dataCommission;

  const { dateTimeOption, fromDate, toDate } = filter;

  const statistic = IS_DELIVERED ? statisticDL : statisticWH;
  const { Total = '', Kg = '', PercentKG = '', PercentTotal = '', TotalKg = '' } = statistic;
  const items = SELECT_DATE_OPTIONS?.map((i) => ({ ...i, label: <Item title={i?.label} /> }));

  const onSelectDate = ({ _, key }) => {
    const selected = dateTimeOption === +key;
    if (+key === 6) {
      dispatch(
        commonActions.onChangeRangeCalendar({
          startDate: fromDate ? moment(fromDate) : moment(),
          endDate: toDate ? moment(toDate) : moment(),
        })
      );
      return setOpenRangeCalendar(true);
    }
    if (selected) return;
    dispatch(importWareHouseActions.onChangeFilter({ dateTimeOption: +key }));
  };

  const onChangeCustomDate = ({ startDate, endDate }) => {
    dispatch(
      importWareHouseActions.onChangeFilter({
        dateTimeOption: 6,
        fromDate: moment(startDate).format(),
        toDate: moment(endDate).format(),
      })
    );
    setOpenRangeCalendar(false);
  };
  return (
    <SearchDateStyled>
      <div className="search-date-wrapper">
        <div className="search-date-tab">
          <div
            className={classNames('search-date-tab-item text-ellipsis', {
              'search-date-tab-item__active': true,
            })}
          >
            {t('store:by-the-time')}
          </div>
          {/* <Popover
            overlayClassName="login-step"
            open={openRangeCalendar}
            onOpenChange={(e) => !e && setOpenRangeCalendar(false)}
            trigger="click"
            arrow={false}
            placement="bottomLeft"
            title={null}
            content={<CustomRangeCalendar onConfirm={onChangeCustomDate} />}
          >
            <div />
          </Popover> */}

          <Dropdown
            overlayClassName="select-options-dropdown"
            placement="bottom"
            trigger={['click']}
            menu={{
              onClick: onSelectDate,
              items,
              selectable: true,
              selectedKeys: [dateTimeOption.toString()],
            }}
          >
            <div className="search-date-select-wrapper">
              <div
                className={classNames('search-date-tab-item', {
                  'search-date-tab-item__active': false,
                })}
              >
                {SELECT_DATE_OPTIONS?.find((i) => +i?.key === dateTimeOption)?.label ||
                  t('common:all')}
              </div>
              {!!dateTimeOption ? (
                <SvgIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    onSelectDate({ key: 0 });
                  }}
                  name="red_x"
                  className="d-flex"
                />
              ) : (
                <SvgIcon name="down_fill" className="search-date-icon search-date-icon__down" />
              )}{' '}
            </div>
          </Dropdown>
        </div>

        {IS_COMMISSION ? (
          <>
            <div className="search-date-commission">
              <Row gutter={[0, 0]} className="align-center">
                <Col className="search-date-commission-img-view">
                  <img alt="" src={Men} className="search-date-commission-img" />
                </Col>
                <Col flex="auto" className="search-date-commission-view">
                  <div className="search-date-commission-text">
                    {userInfo?.isAdmin ? t('store:total-income') : t('store:total-income-user')}
                  </div>
                  <div className="search-date-commission-value">
                    <span>₫</span> {numberWithCommas(totalMoney)}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <Row className="search-date-detail">
            <Col className="max-55">
              <div
                className={classNames('search-date-detail-item-v2', {
                  'search-date-detail-item-v2-china': language == 'zh',
                })}
                // className="search-date-detail-item-v2"
              >
                <div className="search-date-detail-item-header">
                  <SvgIcon
                    name="box"
                    className=" search-date-detail-item-header-icon search-date-detail-item-header-icon__v2 search-date-detail-item-header-icon__box"
                  />
                  <div className="search-date-detail-item-header-text search-date-detail-item-header-text__v2 text-ellipsis search-date-detail-item-header-text__bill">
                    {t('store:bill-of-lading')}
                  </div>
                </div>
                <div className="search-date-detail-item-number text-ellipsis">
                  {numberWithCommas(Total) || 0}
                </div>
                <div className="search-date-detail-item-footer search-date-detail-item-footer__v2">
                  <SvgIcon
                    name={PercentTotal >= 0 ? 'arrow_up_right' : 'arrow_down_left'}
                    className={classNames('search-date-detail-footer-icon', {
                      'search-date-detail-footer-icon__up': PercentTotal >= 0,
                      'search-date-detail-footer-icon__down': PercentTotal < 0,
                    })}
                  />
                  <div
                    className={classNames('search-date-detail-item-footer-percent text-ellipsis', {
                      'search-date-detail-item-footer-percent__up': PercentTotal >= 0,
                      'search-date-detail-item-footer-percent__down': PercentTotal < 0,
                    })}
                  >
                    %{PercentTotal?.toFixed?.(0) || 0}
                  </div>
                </div>
              </div>
            </Col>
            <Col className="flex-1">
              <div
                className={classNames(
                  'search-date-detail-item-v2 search-date-detail-item-v2__has-border',
                  {
                    'search-date-detail-item-v2-china search-date-detail-item-v2__has-border':
                      language == 'zh',
                  }
                )}
                // className="search-date-detail-item-v2 search-date-detail-item-v2__has-border"
              >
                <div className="search-date-detail-item-header">
                  <SvgIcon
                    name="weigh_icon"
                    className=" search-date-detail-item-header-icon search-date-detail-item-header-icon__v2 search-date-detail-item-header-icon__weigh"
                  />
                  <div className="search-date-detail-item-header-text search-date-detail-item-header-text__v2 text-ellipsis search-date-detail-item-header-text__total">
                    {t('store:total-kg')}
                  </div>
                </div>
                <div className="search-date-detail-item-number text-ellipsis">
                  {numberWithCommas(Kg || TotalKg) || 0}
                </div>
                <div className="search-date-detail-item-footer search-date-detail-item-footer__v2">
                  <SvgIcon
                    name={PercentKG >= 0 ? 'arrow_up_right' : 'arrow_down_left'}
                    className={classNames('search-date-detail-footer-icon', {
                      'search-date-detail-footer-icon__up': PercentKG >= 0,
                      'search-date-detail-footer-icon__down': PercentKG < 0,
                    })}
                  />
                  <div
                    className={classNames('search-date-detail-item-footer-percent text-ellipsis', {
                      'search-date-detail-item-footer-percent__up': PercentKG >= 0,
                      'search-date-detail-item-footer-percent__down': PercentKG < 0,
                    })}
                  >
                    %{PercentKG?.toFixed?.(0) || 0}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>

      {openRangeCalendar ? (
        <div className="view-date-picker">
          <CustomRangeCalendar
            onConfirm={onChangeCustomDate}
            onClose={() => setOpenRangeCalendar(false)}
          />
        </div>
      ) : null}
    </SearchDateStyled>
  );
};

export default memo(SearchDate);
