import { Col, Row } from 'antd';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/commonSlice';
import { SelectYearStyled } from './styled';

const SelectYear = ({ onNext, onBack }) => {
  const { t } = useTranslation();
  const { date } = useSelector((state) => state.common.rangeCalendar);

  const dispatch = useDispatch();
  const year = +date?.format('YYYY');
  const yearDecade = year - (year % 10);

  const onChangeYear = (year, isNext) => {
    dispatch(commonActions.onChangeRangeCalendar({ date: date.year(year) }));
    if (isNext) onNext?.();
  };

  return (
    <SelectYearStyled>
      <div className="modern-calendar-note">
        {t('common:select-year')}{' '}
        <SvgIcon name="prev" className="modern-calendar-icon-close" onClick={onBack} />
      </div>
      <div className="main-view">
        <div className="calendar-header">
          <div className="calendar-header-date">
            {yearDecade} - {yearDecade + 9}
          </div>

          <div className="calendar-header-icon-wrapper">
            <SvgIcon
              onClick={() => onChangeYear(year - 10)}
              name="prev"
              className={classNames('calendar-header-icon', { disabled: yearDecade <= 1900 })}
            />

            <SvgIcon
              onClick={() => onChangeYear(year + 10)}
              name="prev"
              className={classNames('calendar-header-icon calendar-header-icon__next', {
                disabled: yearDecade >= 2020,
              })}
            />
          </div>
        </div>

        <Row className="select-year-view" gutter={[15, 15]}>
          {Array.from(Array(12))?.map((_, idx) => {
            const currentYear = Number(yearDecade + idx);
            return (
              <Col
                key={currentYear}
                onClick={() => onChangeYear(currentYear, true)}
                span={8}
                className={classNames('select-year-item', {
                  'select-year-item__selected': year % 10 === idx,
                  'select-year-item__diabled': currentYear - yearDecade >= 10,
                })}
              >
                {currentYear}
              </Col>
            );
          })}
        </Row>
      </div>
    </SelectYearStyled>
  );
};

export default memo(SelectYear);
