import { t } from 'i18next';

export const SELECT_DATE_OPTIONS = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 1,
    label: t('store:today'),
  },
  /*{
    key: 2,
    label: t('store:this-week'),
  },*/
  {
    key: 3,
    label: t('store:this-month'),
  },
  {
    key: 4,
    label: t('store:last-month'),
  },
  {
    key: 5,
    label: t('store:this-year'),
  },
  {
    key: 6,
    label: t('store:custom'),
  },
];

export const SELECT_STATUS_OPTIONS = [
  {
    key: 0,
    label: t('common:all'),
    isChina: false,
  },
  {
    key: 6,
    label: t('store:verification-in-progress'),
    isChina: true,
  },
  {
    key: 7,
    label: t('store:china-status-export'),
    isChina: true,
  },
  {
    key: 5,
    label: t('store:china-status-not-export'),
    isChina: true,
  },
  {
    key: 3,
    label: t('store:delivered'),
    isChina: false,
  },
  {
    key: 1,
    label: t('store:not-give'),
    isChina: false,
  },
];

export const SELECT_STATUS_DELIVERED_OPTIONS = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 9,
    label: t('store:received'),
  },
  {
    key: 8,
    label: t('store:not-collected'),
  },
];

export const SELECT_STATUS_DELIVERED_OPTIONS_USER = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 9,
    label: t('store:paid-user'),
  },
  {
    key: 8,
    label: t('store:not-pay-user'),
  },
];

export const SELECT_STATUS_COMMISSION_OPTIONS = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 1,
    label: t('store:paid'),
  },
  {
    key: 2,
    label: t('store:widthdraw'),
  },
  {
    key: 3,
    label: t('store:wait-to-pay'),
  },
];

export const SELECT_STATUS_COMMISSION_OPTIONS_USER = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 3,
    label: t('store:widthdraw-user'),
  },
  {
    key: 1,
    label: t('store:wait-widthdraw-user'),
  },
  {
    key: 2,
    label: t('store:widthdrawing'),
  },
];

export const SELECT_GENDER = [
  {
    key: 0,
    label: t('common:male'),
  },
  {
    key: 1,
    label: t('common:female'),
  },
  {
    key: 2,
    label: t('common:other'),
  },
];

export const SELECT_BANK = [
  {
    key: 'techcombank',
    label: 'techcombank',
  },
];

export const DELIVERED_STATUS = {
  PAID: 9,
  NOT_PAY: 8,
};

export const SELECT_STATUS_STAFF = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 1,
    label: t('admin:active'),
    ic: 'employee_active',
  },
  {
    key: 2,
    label: t('admin:retired'),
    ic: 'employee_off',
  },
];

export const SELECT_STATUS_CUSTOMER = [
  {
    key: 0,
    label: t('common:all'),
  },
  {
    key: 1,
    label: t('admin:active'),
  },
  {
    key: 2,
    label: t('admin:wait_code'),
  },
  {
    key: 3,
    label: t('admin:looked'),
  },
];
