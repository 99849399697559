import { Row } from 'antd';
import { Item } from 'pages/store/components/TabStatusStore';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from 'redux/adminSlice';
import { TabStaffStyled } from '../styled';

export const TAB_ID_STATUS_ADMIN = {
  STAFF: 'STAFF',
  SUPPERLIER: 'SUPPERLIER',
  CUSTOMER: 'CUSTOMER',
};

const TabStatusAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDark = useSelector((state) => state.common.darkmode);
  const filter = useSelector((state) => state.admin.filter);
  const { statusAdmin } = filter;
  const onChangeStatusStore = (id) =>
    id !== statusAdmin && dispatch(adminActions.changeStatus(id));

  const LIST_ACTION = useMemo(() => {
    return [
      {
        image: 'tab_customer',
        text: t('admin:customer'),
        id: [TAB_ID_STATUS_ADMIN.CUSTOMER],
      },
      {
        image: 'tab_supplier',
        text: t('admin:supplier'),
        id: [TAB_ID_STATUS_ADMIN.SUPPERLIER],
      },
      {
        image: 'tab_staff',
        text: t('admin:staff'),
        id: [TAB_ID_STATUS_ADMIN.STAFF],
      },
    ];
  }, [isDark]);

  return (
    <TabStaffStyled>
      <Row className="space-between">
        {LIST_ACTION?.map((item, index) => (
          <Item
            key={`${item?.id}_${index}`}
            {...item}
            active={item.id?.includes(statusAdmin)}
            onClick={() => onChangeStatusStore(item?.id?.[0])}
          />
        ))}
      </Row>
    </TabStaffStyled>
  );
};

export default memo(TabStatusAdmin);
