import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { departmentActions } from 'redux/departmentSlice';
import {
  getListDepartment,
  updateDepartment,
  getListDepartmentUser,
  updatePosition,
  deletePosition,
  deleteDepartment,
} from 'services/departmentService';
import { t } from 'i18next';

function* getListDep(action) {
  try {
    const res = yield call(getListDepartment, action.payload);
    if (!res) return yield put(departmentActions.getListDepFailed());
    yield put(departmentActions.getListDepSuccess(res?.data));
  } catch (error) {
    yield put(departmentActions.getListDepFailed());
  }
}

function* insertDep(action) {
  try {
    const res = yield call(updateDepartment, action.payload);
    if (!res) return yield put(departmentActions.insertDepFailed());
    toast.success(t('common:success'));
    yield put(departmentActions.getListDep());
    yield put(departmentActions.getListDepForUser({ userId: action.payload?.userId }));
  } catch (error) {
    yield put(departmentActions.insertDepFailed());
  }
}

function* getListDepForUser(action) {
  try {
    const res = yield call(getListDepartmentUser, action.payload);
    if (!res) return yield put(departmentActions.getListDepForUserFailed());
    yield put(
      departmentActions.getListDepForUserSuccess({ data: res.data, userId: action.payload?.userId })
    );
  } catch (error) {
    yield put(departmentActions.getListDepForUserFailed());
  }
}

function* insertPos(action) {
  try {
    const res = yield call(updatePosition, action.payload);
    if (!res) return yield put(departmentActions.insertPosFailed());
    toast.success(t('common:success'));
    yield put(departmentActions.getListDepForUser({ userId: action.payload?.userId }));
  } catch (error) {
    yield put(departmentActions.insertPosFailed());
  }
}

function* deletePos(action) {
  try {
    const res = yield call(deletePosition, action.payload);
    if (!res) return yield put(departmentActions.deletePosFailed());
    toast.success(t('common:success'));
    yield put(departmentActions.getListDepForUser({ userId: action.payload?.userId }));
  } catch (error) {
    yield put(departmentActions.deletePosFailed());
  }
}

function* deleteDept(action) {
  try {
    const res = yield call(deleteDepartment, action.payload);
    if (!res) return yield put(departmentActions.deleteDeptFailed());
    toast.success(t('common:success'));
    yield put(departmentActions.getListDep());
    yield put(departmentActions.getListDepForUser({ userId: action.payload?.userId }));
  } catch (error) {
    yield put(departmentActions.deleteDeptFailed());
  }
}
export default function* departmentSaga() {
  yield all([takeLatest(departmentActions.getListDep.type, getListDep)]);
  yield all([takeLatest(departmentActions.insertDep.type, insertDep)]);
  yield all([takeLatest(departmentActions.getListDepForUser.type, getListDepForUser)]);
  yield all([takeLatest(departmentActions.insertPos.type, insertPos)]);
  yield all([takeLatest(departmentActions.deletePos.type, deletePos)]);
  yield all([takeLatest(departmentActions.deleteDept.type, deleteDept)]);
}
