import isNumber from 'lodash/isNumber';
import { Encoder } from './encoder';

export const SYMBOLS_REGEX =
  /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|`|-|{|}|\||\\/g; //eslint-disable-line

export function generateRandomString() {
  return Math.random().toString(36).substr(7);
}

export function generateCode(str, underscore) {
  if (!str) return '';
  str = str?.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // eslint-disable-next-line no-useless-escape
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    ' '
  );
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  str = str.toUpperCase();
  const matches = str.match(/\b(\w)/g);
  if (!matches) return '';
  const acronym = matches.join('');
  if (underscore) return acronym + '_';
  return acronym;
}

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function validatePhone(phone) {
  if (
    ((phone.startsWith('01') ||
      phone.startsWith('028') ||
      phone.startsWith('023') ||
      phone.startsWith('02')) &&
      phone.length === 11) ||
    ((phone.startsWith('03') ||
      phone.startsWith('05') ||
      phone.startsWith('07') ||
      phone.startsWith('08') ||
      phone.startsWith('09')) &&
      phone.length === 10)
  )
    return true;
  return false;
}

export function formatPhone(phone) {
  return phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3');
}

export function getMsgClient(message) {
  return message.indexOf('[!|') !== -1 && message.indexOf('|!]') !== -1
    ? message.split('[!|')[0].trim() + message.split('|!]')[1]
    : message;
}

export function validateEmail(email) {
  const re = getRegexEmail();
  return re?.test(String(email)?.toLowerCase());
}

export function getRegexEmail() {
  // eslint-disable-next-line no-useless-escape
  const re = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  return re;
}

export function validateMobile(mobile) {
  const re = getRegexMobile();
  return re?.test(String(mobile)?.toLowerCase());
}

export function getRegexMobile() {
  const re = /((\+84|84|0)+(9|3|7|8|5)+([0-9]{8})\b)/g;
  return re;
}

export const decodeHtml = (textInput) => {
  if (!textInput) return '';
  const removeHtml = textInput.replace(/<\/?[^>]+(>|$)/g, '');
  const decode = Encoder.htmlDecode(removeHtml);
  return decode;
};

export const stripHtml = (textInput) => {
  if (!textInput) return '';
  return textInput.replace(/<\/?[^>]+(>|$)/g, '');
};

export const convertContent = (text) => {
  let content;
  try {
    const parseText = JSON.parse(decodeHtml(text));
    content = [parseText?.ActionName, parseText?.ProcessedContent]
      .join(parseText?.ActionName && parseText?.ProcessedContent ? ': ' : '')
      .concat(parseText?.SendReportHandle ? ` ${parseText?.SendReportHandle}` : '');
  } catch (e) {
    content = text;
  }
  return content;
};

export const fixImplicateText = (text) => {
  if (!text) return '';
  return text
    ?.replace(/↵/g, ' ')
    ?.replace(/(<div>|<p>)/g, '&nbsp;<span>')
    ?.replace(/(<\/div>|<\/p>)/g, '</span>')
    ?.replace(/(<div |<p )/g, '&nbsp;<span ')
    ?.replace(/(<br>|<br\/>|<\/br>|<br \/>)/g, ' ');
};

export function getTimeSpent() {
  const re = /^(d[g|h]?)?(d{1}?[05][m|p])?$/;
  return re;
}

export function formatUserName(fullName, userName, deptName) {
  if (!userName) return `${fullName} - ${deptName}`;
  if (!deptName) return ` ${userName} - ${fullName} `;
  return `${fullName} - (${userName}) - ${deptName}`;
}

export const numberWithCommas = (x = 0) => {
  // const moneyStr = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
  //   .format(x)
  //   ?.replace('€', '')
  //   ?.trim();

  // const moneyInt = moneyStr?.slice(0, -3);
  // const moneyDecimal = +moneyStr?.slice(-3, -1)?.replace(',', '.') || 0;
  // const decimal =
  //   isNumber(moneyDecimal) && moneyDecimal > 0
  //     ? moneyDecimal?.toString().replace('.', ',')?.slice(1)
  //     : '';
  return Number(x)?.toFixed(0).toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0; 
};

export const protectEmail = function (user_email) {
  // Declare variables avg, splitted, part1, and part2
  var avg, splitted, part1, part2;
  // Split the user_email string into two parts using "@" as the delimiter and store them in the splitted array
  splitted = user_email.split('@');
  // Store the first part of the email in the part1 variable
  part1 = splitted[0];
  // Calculate the average length of the first part of the email
  avg = part1.length / 2;
  // Extract the substring of part1 from index 0 to (part1.length - avg) and store it back in part1
  part1 = part1.substring(0, part1.length - avg);
  // Store the second part of the email in the part2 variable
  part2 = splitted[1];
  // Return a protected version of the email by concatenating part1, "...@", and part2
  return part2 ? part1 + '...@' + part2 : part1 + '...';
};

export const protectPhone = function (phone) {
  return phone?.toString()?.slice(0, -4) + '****';
};

export const formatMoneyVND = (
  config = {
    amount: '',
    uppercaseUnit: '',
    showSuffix: '',
  }
) => {
  const separateMoney = Intl.NumberFormat('vi-VN').format(Number(config.amount));
  const unit = config.uppercaseUnit ? 'Đ' : 'đ';
  return `${separateMoney} ${config.showSuffix ? unit : ''}`;
};
