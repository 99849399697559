import Services from 'services';

// department

const getListDepartmentUser = async (params) =>
  await Services.get('/api/DepartmentPosition/user', { params });
const getListDepartment = async (params) =>
  await Services.get('/api/DepartmentPosition/department', { params });
const updateDepartment = async (body) =>
  await Services.post('/api/DepartmentPosition/department', body);
const deleteDepartment = async (params) =>
  await Services.delete('/api/DepartmentPosition/department', { params });

// position

const updatePosition = async (body) =>
  await Services.post('/api/DepartmentPosition/position', body);
const deletePosition = async (params) =>
  await Services.delete('/api/DepartmentPosition/position', { params });

export {
  getListDepartmentUser,
  getListDepartment,
  updateDepartment,
  deleteDepartment,
  updatePosition,
  deletePosition,
};
