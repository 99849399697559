import { createSlice } from '@reduxjs/toolkit';
import { TAB_ID_STATUS_ADMIN } from 'pages/admin/components/TabStatusAdmin';

const initialState = {
  loading: false,
  data: {},
  pageIndex: 0,
  showRight: false,
  loadingDetail: false,
  staff: {},
  customer: {},
  showDetail: false,
  filter: {
    statusAdmin: TAB_ID_STATUS_ADMIN.CUSTOMER,
    pageSize: 20,
    getCount: true,
    status: 0,
    search: '',
    employeeId: [],
    categoryId: 0,
    identity: '',
    departmentIds: [],
    nationId: 0,
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    onChangeShowDetail(state, action) {
      state.showDetail = action.payload;
      if (!action.payload) {
        state.customer = {};
        state.staff = {};
      }
    },
    updateCustomer(state) {
      state.loadingDetail = true;
    },
    updateCustomerFailed(state) {
      state.loadingDetail = false;
    },

    deleteCustomer(state) {
      state.loadingDetail = true;
    },
    deleteCustomerFailed(state) {
      state.loadingDetail = false;
    },
    updateStaff(state) {
      state.loadingDetail = true;
    },
    updateStaffFailed(state) {
      state.loadingDetail = false;
    },
    insertStaff(state) {
      state.loadingDetail = true;
    },
    insertStaffSuccess(state) {},
    insertStaffFailed(state) {
      state.loadingDetail = false;
    },
    deleteStaff(state) {
      state.loadingDetail = true;
    },
    deleteStaffFailed(state) {
      state.loadingDetail = false;
    },

    onChangeShowRight(state, action) {
      state.showRight = action.payload;
    },
    changePageIndex(state, action) {
      state.pageIndex = action.payload;
    },
    onChangeFilter(state, actions) {
      state.filter = { ...state.filter, ...actions?.payload };
    },
    changeStatus(state, actions) {
      state.filter = { ...initialState.filter, statusAdmin: actions?.payload };
    },
    getListDataStaff(state) {
      state.loading = true;
    },
    getListDataCustomer(state) {
      state.loading = true;
    },
    getListDataSuccess(state, action) {
      state.loading = false;
      state.data = { ...state.data, ...action.payload };
    },
    getListDataFailed(state) {
      state.loading = false;
      state.data = {};
    },
    getDetailStaff(state) {
      state.loadingDetail = true;
    },
    getDetailStaffSuccess(state, action) {
      state.loadingDetail = false;
      state.staff = action.payload;
    },
    getDetailStaffFailed(state) {
      state.loadingDetail = false;
      state.customer = {};
    },
    getDetailCustomer(state) {
      state.loadingDetail = true;
    },
    getDetailCustomerSuccess(state, action) {
      state.loadingDetail = false;
      state.customer = action.payload;
    },
    getDetailCustomerFailed(state) {
      state.loadingDetail = false;
      state.customer = {};
    },
    clearImportWareHouse(state) {
      state.data = {};
    },
    clearFilter(state) {
      state.filter = initialState.filter;
    },
    clearData: () => initialState,
  },
});

// Actions
export const adminActions = adminSlice.actions;

// Reducer
const adminReducer = adminSlice.reducer;
export default adminReducer;
