import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  listDepForUser: [],
  listDep: [],
  listPosForUser: [],
};

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    updatePosForUser(state) {
      state.loading = true;
    },
    updatePosForUserFailed(state) {
      state.loading = false;
    },
    onChangeListPosForUser(state, action) {
      state.listPosForUser = action.payload;
    },
    getListDepForUser(state) {
      state.loading = true;
    },
    getListDepForUserSuccess(state, action) {
      state.loading = false;
      state.listDepForUser = action.payload.data;
      if(action.payload.userId) state.listPosForUser = action.payload.data?.filter((i) => i?.isPosition)?.map((i) => i?.positionId);
    },
    getListDepForUserFailed(state) {
      state.loading = false;
      state.listDepForUser = [];
    },
    insertDep(state) {
      state.loading = true;
    },
    insertDepFailed(state) {
      state.loading = false;
    },
    insertPos(state) {
      state.loading = true;
    },
    insertPosFailed(state) {
      state.loading = false;
    },
    deletePos(state) {
      state.loading = true;
    },
    deletePosFailed(state) {
      state.loading = false;
    },
    deleteDept(state) {
      state.loading = true;
    },
    deleteDeptFailed(state) {
      state.loading = false;
    },
    getListDep(state) {
      state.loading = true;
    },
    getListDepSuccess(state, action) {
      state.loading = false;
      state.listDep = action.payload;
    },
    getListDepFailed(state) {
      state.loading = false;
      state.listDep = [];
    },
    clearData: () => initialState,
  },
});

// Actions
export const departmentActions = departmentSlice.actions;

// Reducer
const departmentReducer = departmentSlice.reducer;
export default departmentReducer;
