import Services from 'services';

const getUserPermission = async (params) => await Services.get('/api/Permission/user', { params });
const updateUserPermission = async (body) => await Services.post('/api/Permission/user', body);
const getPositionPermission = async (params) =>
  await Services.get('/api/Permission/position', { params });
const updatePositionPermission = async (body) =>
  await Services.post('/api/Permission/position', body);

export { getPositionPermission, getUserPermission, updatePositionPermission, updateUserPermission };
