import { memo, useCallback, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import CustomRangeCalendar from './CustomRangerCalendar';
import { RangeCalendarStyled } from './styled';
import { useDispatch } from 'react-redux';
import { commonActions } from 'redux/commonSlice';
import SelectMonth from './SelectMonth';
import SelectYear from './SelectYear';

const RangeCalendar = ({ onConfirm, range = true, onClose }) => {
  const slideRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(commonActions.onResetRangeCalendar());
    };
  }, []);

  const settings = {
    ref: slideRef,
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    swipe: false,
    arrows: false,
    adaptiveHeight: true,
  };

  const onNext = useCallback(() => {
    slideRef.current.slickNext();
  }, [slideRef.current]);


  const onBack = useCallback(() => {
    slideRef.current.slickPrev();
  }, [slideRef.current]);
  return (
    <RangeCalendarStyled>
      <Slider {...settings}>
        <CustomRangeCalendar range={range} onNext={onNext} onConfirm={onConfirm} onClose={onClose}/>
        <SelectYear onNext={onNext} onBack={onBack}/>
        <SelectMonth onNext={onNext} onBack={onBack}/>
      </Slider>
    </RangeCalendarStyled>
  );
};

export default memo(RangeCalendar);
