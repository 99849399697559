import styled from 'styled-components';

export const CustomRangeStyled = styled.div`
  position: relative;
  min-height: 510px;

  .calendar-export {
    position: absolute;
    bottom: 20px;
    right: 15px;
    border-radius: 5px;
    background-color: #ff541a;
    padding: 10px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    /* height: 36px;
    width: 150px; */
    text-align: center;
  }

  .calendar {
    width: 100%;
    padding: 15px;
    padding-bottom: 70px;
    overflow: hidden;
    background-color: var(--calendar-view-background);

    &--days {
      font-size: 0;

      span {
        width: 14.28571%;
        display: inline-block;
        text-align: center;
        user-select: none;
        cursor: pointer;
        margin: 8px 0;
        line-height: 34px;
        position: relative;
        font-size: 16px;

        &.label {
          text-transform: uppercase;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.3);
          font-size: 14px;
          cursor: initial;
        }
        &.muted .date-item {
          color: var(--calendar-disabled-date);
        }
        &.start,
        &.between,
        &.end {
          .date-item {
            color: var(--calendar-selected-date);
            border-radius: 50%;
          }
        }

        &.active .date-item {
          border-radius: 50%;
          border: 1px solid var(--calendar-today-border);
        }

        &.between {
          border-radius: 0;
        }

        &.start,
        &.end {
          .date-item {
            background-color: var(--calendar-selected-background);
          }
        }
        &.between {
          background-color: var(--calendar-selected-background-blur);
        }

        &.start.end {
          &::before {
            display: none;
          }
          border-radius: 50%;
          background-color: transparent;
        }
        &.end:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 50%;
          background-color: var(--calendar-selected-background-blur);
          width: 50%;
        }
        &.start:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          background-color: var(--calendar-selected-background-blur);
          width: 50%;
        }
        &.between:nth-child(7n):after,
        &.start:nth-child(7n):after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 100%;
          background-color: var(--calendar-selected-background-blur);
          width: 20px;
        }

        &.between:nth-child(7n + 1):after,
        &.end:nth-child(7n + 1):after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 100%;
          background-color: var(--calendar-selected-background-blur);
          width: 20px;
        }

        &.start.end:after {
          display: none;
        }
      }
    }
  }

  .calendar-week-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    text-align: center;
    color: #737373;
  }
  .date-item-wrapper {
    height: 32px;
    position: relative;
  }
  .date-item {
    width: 32px;
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    color: #444444;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RangeCalendarStyled = styled.div`
  background-color: #eceff1;
  border-radius: 10px 0 0;

  .modern-calendar-note {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #3a404e;
    text-transform: uppercase;
    padding: 19px;
    position: relative;
  }

  .modern-calendar-icon-close {
    position: absolute;
    display: flex;
    top: 50%;
    left: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: #ff541a;
        fill: #ff541a;
      }
    }
  }
  .main-view {
    width: 100%;
    padding: 15px;
    overflow: hidden;
    flex: 1;
    background-color: var(--calendar-view-background);
  }
  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .calendar-header-date-wrapper {
    cursor: pointer;
  }
  .calendar-header-date-wrapper,
  .calendar-header-icon-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .calendar-header-date {
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
    color: #737373;
    text-transform: uppercase;
  }
  .calendar-header-icon-change-year {
    display: flex;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: var(--calendar-icon);
      }
    }
  }
  .calendar-header-icon {
    display: flex;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: var(--calendar-icon);
      }
    }
    &__next {
      rotate: 180deg;
    }
  }
`;

export const SelectYearStyled = styled.div`
  min-height: 510px;
  display: flex;
  flex-direction: column;
  .disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }
  .select-year-view {
    margin: 20px 0px 30px;
  }
  .select-year-item {
    text-align: center;
    padding: 5px 0px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    cursor: pointer;
    color: #444;
    &__selected {
      font-weight: 600;
      color: var(--calendar-selected-date);
      background-color: var(--calendar-selected-background);
    }
    &__diabled {
      color: var(--calendar-disabled-date);
    }
  }
`;

export const SelectMonthStyled = styled(SelectYearStyled)`
  min-height: 510px;
`;
