import { toast } from 'react-toastify';
import { t } from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { adminActions } from 'redux/adminSlice';
import {
  getListAccount,
  getListClient,
  getDetailAccount,
  getDetailClient,
  createUpdateClient,
  deleteAccount,
  deleteClient,
  createUpdateAccount,
  updatePositionUser,
} from 'services/adminService';
import { uploadImage } from 'services/userService';
import { departmentActions } from 'redux/departmentSlice';
import { updateUserPermission } from 'services/permissionService';
import { userActions } from 'redux/userInfo';

function* getListDataCustomer(action) {
  try {
    const res = yield call(getListClient, action.payload);
    if (!res) return yield put(adminActions.getListDataFailed());
    yield put(
      adminActions.getListDataSuccess({
        ...res?.data,
        data: res?.data?.ListClient,
        ListClient: undefined,
      })
    );
  } catch (error) {
    yield put(adminActions.getListDataFailed());
  }
}

function* getListDataStaff(action) {
  try {
    const res = yield call(getListAccount, action.payload);
    if (!res) return yield put(adminActions.getListDataFailed());
    yield put(
      adminActions.getListDataSuccess({
        ...res?.data,
        ListUser: undefined,
        data: res?.data?.ListUser,
      })
    );
  } catch (error) {
    yield put(adminActions.getListDataFailed());
  }
}

function* getDetailCustomer(action) {
  try {
    const res = yield call(getDetailClient, action.payload);
    if (!res) return yield put(adminActions.getDetailCustomerFailed());
    yield put(adminActions.getDetailCustomerSuccess(res?.data));
  } catch (error) {
    yield put(adminActions.getDetailCustomerFailed());
  }
}

function* getDetailStaff(action) {
  try {
    const res = yield call(getDetailAccount, action.payload);
    if (!res) return yield put(adminActions.getDetailStaffFailed());
    yield put(adminActions.getDetailStaffSuccess(res?.data));
  } catch (error) {
    yield put(adminActions.getDetailStaffFailed());
  }
}

function* updateCustomer(action) {
  try {
    const res = yield call(createUpdateClient, {
      ...action.payload,
      filter: undefined,
      formData: undefined,
      formQrZalo: undefined,
      formQrWechat: undefined,
    });
    if (!res) return yield put(adminActions.updateCustomerFailed());
    yield put(adminActions.onChangeShowDetail(false));
    toast.success(t('common:success'));
    yield put(adminActions.getListDataCustomer({ ...action.payload?.filter }));
  } catch (error) {
    yield put(adminActions.updateCustomerFailed());
  }
}
function* updateStaff(action) {
  try {
    const formQrZalo = action?.payload?.formQrZalo;
    const formQrWechat = action?.payload?.formQrWechat;
    const formUpload = action?.payload?.formData;

    const [resUpload, res, resQrZalo, resQrWechat] = yield all([
      formUpload && call(uploadImage, formUpload),
      call(createUpdateAccount, {
        ...action.payload,
        filter: undefined,
        formData: undefined,
        formQrZalo: undefined,
        formQrWechat: undefined,
      }),
      formQrZalo && call(uploadImage, formQrZalo),
      formQrWechat && call(uploadImage, formQrWechat),
    ]);
    if (
      !res ||
      (formUpload && !resUpload) ||
      (formQrWechat && !resQrWechat) ||
      (formQrZalo && !resQrZalo)
    )
      return yield put(adminActions.updateStaffFailed());
    yield put(adminActions.onChangeShowDetail(false));
    toast.success(t('common:success'));
    if (action?.payload?.isUserLogin) yield put(userActions.getUserInfo());
    yield put(adminActions.getListDataStaff({ ...action.payload?.filter }));
  } catch (error) {
    yield put(adminActions.updateStaffFailed());
  }
}

function* insertStaffSuccess(action) {
  try {
    const formUpload = action?.payload?.formData;
    const formQrZalo = action?.payload?.formQrZalo;
    const formQrWechat = action?.payload?.formQrWechat;
    if (formUpload) formUpload.append('UserId', action?.payload?.id);
    if (formQrZalo) formQrZalo.append('UserId', action?.payload?.id);
    if (formQrWechat) formQrWechat.append('UserId', action?.payload?.id);

    const listPer = action?.payload?.listPer;
    const listPos = action?.payload?.listPos;

    const [resUpload, resQrZalo, resQrWechat, resPer, resPos] = yield all([
      formUpload && call(uploadImage, formUpload),
      formQrZalo && call(uploadImage, formQrZalo),
      formQrWechat && call(uploadImage, formQrWechat),
      listPer?.length &&
        call(updateUserPermission, {
          userId: action?.payload?.id,
          listPermission: listPer,
        }),
      listPos?.length &&
        call(updatePositionUser, {
          userId: action?.payload?.id,
          listPosition: listPos,
        }),
    ]);
    if (
      (formUpload && !resUpload) ||
      (formQrWechat && !resQrWechat) ||
      (formQrZalo && !resQrZalo) ||
      (listPer?.length && !resPer) ||
      (listPos?.length && !resPos)
    )
      return yield put(adminActions.updateStaffFailed());
    yield put(adminActions.onChangeShowDetail(false));
    toast.success(t('common:success'));
    yield put(adminActions.getListDataStaff({ ...action.payload?.filter }));
  } catch (error) {
    yield put(adminActions.updateStaffFailed());
  }
}

function* insertStaff(action) {
  try {
    const res = yield call(createUpdateAccount, {
      ...action.payload,
      filter: undefined,
      formData: undefined,
      formQrZalo: undefined,
      formQrWechat: undefined,
      listPer: undefined,
      listPos: undefined,
    });

    if (!res) return yield put(adminActions.insertStaffFailed());
    yield put(adminActions.insertStaffSuccess({ ...action.payload, id: res?.data?.id || 100 }));
  } catch (error) {
    yield put(adminActions.insertStaffFailed());
  }
}

function* deleteCustomer(action) {
  try {
    const res = yield call(deleteClient, { ...action.payload, filter: undefined });
    if (!res) return yield put(adminActions.deleteCustomerFailed());

    toast.success(t('common:success'));
    yield put(adminActions.getListDataCustomer({ ...action.payload?.filter }));
    yield put(adminActions.onChangeShowDetail(false));
  } catch (error) {
    yield put(adminActions.deleteCustomerFailed());
  }
}

function* deleteStaff(action) {
  try {
    const res = yield call(deleteAccount, { ...action.payload, filter: undefined });
    if (!res) return yield put(adminActions.deleteStaffFailed());

    toast.success(t('common:success'));
    yield put(adminActions.getListDataStaff({ ...action.payload?.filter }));
    yield put(adminActions.onChangeShowDetail(false));
  } catch (error) {
    yield put(adminActions.deleteStaffFailed());
  }
}

function* updatePosForUser(action) {
  try {
    const res = yield call(updatePositionUser, { ...action.payload, onClose: undefined });
    if (res) {
      if (action.payload?.onClose) action.payload?.onClose();
      toast.success(t('common:success'));
      yield put(
        adminActions.getDetailStaff({ id: action.payload?.userId, userId: action.payload?.userId })
      );
    }
    yield put(departmentActions.updatePosForUserFailed());
  } catch (error) {
    yield put(departmentActions.updatePosForUserFailed());
  }
}

export default function* adminSaga() {
  yield all([takeLatest(adminActions.getListDataCustomer.type, getListDataCustomer)]);
  yield all([takeLatest(adminActions.getListDataStaff.type, getListDataStaff)]);
  yield all([takeLatest(adminActions.getDetailCustomer.type, getDetailCustomer)]);
  yield all([takeLatest(adminActions.getDetailStaff.type, getDetailStaff)]);
  yield all([takeLatest(adminActions.updateCustomer.type, updateCustomer)]);
  yield all([takeLatest(adminActions.updateStaff.type, updateStaff)]);
  yield all([takeLatest(adminActions.insertStaff.type, insertStaff)]);
  yield all([takeLatest(adminActions.insertStaffSuccess.type, insertStaffSuccess)]);

  yield all([takeLatest(adminActions.deleteCustomer.type, deleteCustomer)]);
  yield all([takeLatest(adminActions.deleteStaff.type, deleteStaff)]);
  yield all([takeLatest(departmentActions.updatePosForUser.type, updatePosForUser)]);
}
