import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  listEmpForUser: [],
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    getListEmployeeForUser(state) {
      state.loading = true;
    },
    getListEmployeeForUserSuccess(state, action) {
      state.loading = false;
      state.listEmpForUser = action.payload;
    },
    getListEmployeeForUserFailed(state) {
      state.loading = false;
      state.listEmpForUser = [];
    },

    clearData: () => initialState,
  },
});

// Actions
export const employeeActions = employeeSlice.actions;

// Reducer
const employeeReducer = employeeSlice.reducer;
export default employeeReducer;
