/* eslint-disable no-restricted-globals */
import { USER_INFO } from 'constants/localStorage';
import { isArray, forEach } from 'lodash';

// goi trong useEffect
export const trimData = (data) => {
  if (!data) return data;
  const tempData = isArray(data) ? [] : {};
  forEach(data, (val, keyName) => {
    if (typeof val === 'string') tempData[keyName] = val.trim();
    else if (typeof val === 'object') tempData[keyName] = trimData(val);
    else tempData[keyName] = val;
  });
  return tempData;
};

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const hasPermission = (permission) => {
  const listPermission = JSON.parse(localStorage.getItem(USER_INFO) || {})?.listPermission;
  return listPermission?.includes(permission) || listPermission?.includes('administration');
};
