import { all, call, put, takeLatest } from 'redux-saga/effects';
import { commissionActions } from 'redux/commissionSlice';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import { getList, getEmployeeFilter, withDrawMoney } from 'services/commisionsService';

function* handleGetList(action) {
  try {
    const res = yield call(getList, action.payload);
    if (!res) return yield put(commissionActions.getListCommissionFailed());
    yield put(commissionActions.getListCommissionSuccess(res?.data));
  } catch (error) {
    yield put(commissionActions.getListCommissionFailed());
  }
}

function* handleGetListEmployee(action) {
  try {
    const res = yield call(getEmployeeFilter, action.payload);
    if (!res) return yield put(commissionActions.getListEmployeeFailed());
    yield put(commissionActions.getListEmployeeSuccess(res?.data));
  } catch (error) {
    yield put(commissionActions.getListEmployeeFailed());
  }
}

function* handlewithDrawMoney(action) {
  try {
    const res = yield call(withDrawMoney, { otp: action.payload?.otp });
    if (!res) return yield put(commissionActions.withDrawMoneyFailed());
    if (action.payload?.callback) action.payload?.callback();
    else {
      yield put(commissionActions.withDrawMoneySuccess());
      yield put(importWareHouseActions.onChangeWithDraw(false));
      yield put(commissionActions.getListCommission({...action.payload?.filter}))
    }
    yield put(commissionActions.withDrawMoneyFailed());
  } catch (error) {
    yield put(commissionActions.withDrawMoneyFailed());
  }
}
export default function* commissionSaga() {
  yield all([takeLatest(commissionActions.getListCommission.type, handleGetList)]);
  yield all([takeLatest(commissionActions.getListEmployee.type, handleGetListEmployee)]);
  yield all([takeLatest(commissionActions.withDrawMoney.type, handlewithDrawMoney)]);
}
