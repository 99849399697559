import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deliveredActions } from 'redux/deliveredSlice';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import { TAB_ID_STATUS_STORE } from 'pages/store/components/TabStatusStore';
import { commissionActions } from 'redux/commissionSlice';
import { landingActions } from 'redux/landingSlice';

export const useStatusTab = () => {
  const { filter } = useSelector((state) => state.importWareHouse);
  const { statusStore } = filter;
  const IS_WAREHOUSE = statusStore === TAB_ID_STATUS_STORE.WAREHOUSE;
  const IS_COMMISSION = statusStore === TAB_ID_STATUS_STORE.COMMISSION;
  const IS_DELIVERED = statusStore === TAB_ID_STATUS_STORE.DELIVERED;
  const IS_LANDING = statusStore === TAB_ID_STATUS_STORE.LANDING;

  return { IS_WAREHOUSE, IS_COMMISSION, IS_DELIVERED, IS_LANDING };
};

const useStore = () => {
  const dispatch = useDispatch();
  const { IS_COMMISSION, IS_DELIVERED, IS_WAREHOUSE, IS_LANDING } = useStatusTab();
  const { pageIndex, filter } = useSelector((state) => state.importWareHouse);
  const { statusStore, pageSize } = filter;

  useEffect(() => {
    dispatch(importWareHouseActions.getListDepot());
    dispatch(commissionActions.getListEmployee());
    return () => {
      dispatch(importWareHouseActions.clearData());
      dispatch(deliveredActions.clearData());
      dispatch(commissionActions.clearData());
      dispatch(landingActions.clearData());
    };
  }, []);

  useEffect(() => {
    dispatch(importWareHouseActions.getListImportWareHouseSuccess({}));
    dispatch(deliveredActions.getListDeliveredSuccess({}));
    dispatch(commissionActions.getListCommissionSuccess({}));
    dispatch(landingActions.getListLandingSuccess({}));
  }, [statusStore]);

  useEffect(() => {
    if (statusStore) onChangeFilter();
  }, [filter]);

  useEffect(() => {
    if (pageIndex) getData();
  }, [pageIndex]);

  const onChangeFilter = () => {
    if (pageIndex === 1) getData();
    else dispatch(importWareHouseActions.changePageIndex(1));
  };

  const getData = () => {
    const params = { ...filter, pageIndex };
    if (IS_WAREHOUSE) dispatch(importWareHouseActions.getListImportWareHouse(params));
    if (IS_DELIVERED) dispatch(deliveredActions.getListDelivered(params));
    if (IS_COMMISSION) dispatch(commissionActions.getListCommission(params));
    if (IS_LANDING) dispatch(landingActions.getListLanding(params));
  };

  const onChangePagination = useCallback(
    (page, pageSize) => {
      if (page !== pageIndex) dispatch(importWareHouseActions.changePageIndex(page));
      if (filter?.pageSize !== pageSize)
        dispatch(importWareHouseActions.onChangeFilter({ pageSize }));
    },
    [pageIndex, pageSize]
  );

  return { onChangePagination, IS_COMMISSION, IS_DELIVERED, IS_WAREHOUSE, IS_LANDING };
};

export default useStore;
