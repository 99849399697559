import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  userPermission: [],
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    getUserPermission(state) {
      state.loading = true;
    },
    getUserPermissionSuccess(state, action) {
      state.loading = false;
      state.userPermission = action.payload;
    },
    getUserPermissionFailed(state) {
      state.loading = false;
    },
    getPositionPermission(state) {
      state.loading = true;
    },
    getPositionPermissionSuccess(state, action) {
      state.loading = false;
      state.userPermission = action.payload;
    },
    getPositionPermissionFailed(state) {
      state.loading = false;
    },
    updateUserPermission(state) {
      state.loading = true;
    },
    updateUserPermissionFailed(state) {
      state.loading = false;
    },
    updatePositionPermission(state) {
      state.loading = true;
    },
    updatePositionPermissionFailed(state) {
      state.loading = false;
    },
    clearData: () => initialState,
  },
});

// Actions
export const permissionActions = permissionSlice.actions;

// Reducer
const permissionReducer = permissionSlice.reducer;
export default permissionReducer;
