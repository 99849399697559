import authSaga from 'saga/authSaga';
import userSaga from 'saga/userSaga';
import countrySaga from 'saga/countrySaga';
import discountSaga from 'saga/discountSaga';
import deliveredSaga from 'saga/deliveredSaga';
import importWHSaga from 'saga/importWHSaga';
import articleSaga from 'saga/articleSaga';
import commissionSaga from 'saga/commissionSaga';
import landingSaga from 'saga/landingSaga';
import adminSaga from 'saga/adminSaga';
import departmentSaga from 'saga/departmentSaga';
import empoyeeSaga from 'saga/empoyeeSaga';
import permissionSaga from 'saga/permissionSaga';

import { all, fork } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(countrySaga),
    fork(discountSaga),
    fork(deliveredSaga),
    fork(importWHSaga),
    fork(articleSaga),
    fork(commissionSaga),
    fork(landingSaga),
    fork(adminSaga),
    fork(departmentSaga),
    fork(empoyeeSaga),
    fork(permissionSaga),
  ]);
}
