import { Col, Row } from 'antd';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'redux/commonSlice';
import { SelectMonthStyled } from './styled';

const SelectMonth = ({ onNext, onBack }) => {
  const { t } = useTranslation();
  const { date } = useSelector((state) => state.common.rangeCalendar);

  const dispatch = useDispatch();
  const year = +date?.format('YYYY');
  const month = +date?.month();

  const onChangeYear = (year) =>
    dispatch(commonActions.onChangeRangeCalendar({ date: date.year(year) }));

  const onChangeMonth = (month) => {
    dispatch(commonActions.onChangeRangeCalendar({ date: date.month(month) }));
    onNext?.();
  };
  return (
    <SelectMonthStyled>
      <div className="modern-calendar-note">
        {t('common:select-month')}{' '}
        <SvgIcon name="prev" className="modern-calendar-icon-close" onClick={onBack} />
      </div>
      <div className="main-view">
        <div className="calendar-header">
          <div className="calendar-header-date">{year}</div>

          <div className="calendar-header-icon-wrapper">
            <SvgIcon
              onClick={() => onChangeYear(year - 1)}
              name="prev"
              className={classNames('calendar-header-icon', { disabled: year <= 2000 })}
            />

            <SvgIcon
              onClick={() => onChangeYear(year + 1)}
              name="prev"
              className={classNames('calendar-header-icon calendar-header-icon__next', {
                disabled: year >= 2029,
              })}
            />
          </div>
        </div>

        <Row className="select-year-view" gutter={[15, 15]}>
          {Array.from(Array(12))?.map((_, idx) => {
            const currentMonth = Number(1 + idx);
            return (
              <Col
                key={currentMonth}
                onClick={() => onChangeMonth(idx)}
                span={8}
                className={classNames('select-year-item', {
                  'select-year-item__selected': month === idx,
                })}
              >
                {currentMonth}
              </Col>
            );
          })}
        </Row>
      </div>
    </SelectMonthStyled>
  );
};

export default memo(SelectMonth);
