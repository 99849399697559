import { ConfigProvider } from 'antd';
import vnVN from 'antd/locale/vi_VN';
import zh_CN from 'antd/locale/zh_CN';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ModalUpdateUser from 'components/user/ModalUpdateUser';
import { LANGUAGE, USER_INFO } from 'constants/localStorage';
import { THEME, themeCustom } from 'constants/themes';
import 'libs/i18n';
import { getStorage } from 'libs/storage';
import { createElement, memo, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonActions } from 'redux/commonSlice';
import { countryActions } from 'redux/countrySlice';
import { userActions } from 'redux/userInfo';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ThemeProvider } from 'styled-components';
import DarkTheme from 'theme/DarkTheme';
import LightTheme from 'theme/LightTheme';
import App from './App';
import { store } from './app/store';
import './index.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

const AppWrapper = memo(() => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isDark = useAppSelector((state) => state.common.darkmode);

  useEffect(() => {
    // TẮT DARKTHEME
    /* const isDark = getStorage(DARK_MODE) || !moment().isBetween(moment('07:00', 'HH:mm'), moment('19:00', 'HH:mm'));*/
    const language = getStorage(LANGUAGE);
    const userInfo = getStorage(USER_INFO);
    !!userInfo && dispatch(userActions.setUserInfo(userInfo));
    i18n.changeLanguage(language);
    dispatch(commonActions.changeDarkMode({ darkmode: isDark }));
    dispatch(countryActions.getCountry());
  }, []);

  return (
    <ThemeProvider theme={themeCustom[isDark ? THEME.DARK : THEME.LIGHT]}>
      <ConfigProvider
        locale={i18n?.language === 'zh' ? zh_CN : vnVN}
        theme={{
          token: { colorPrimary: '#FF541A' },
        }}
      >
        <ErrorBoundary>
          {createElement(isDark ? DarkTheme : LightTheme)}
          <App />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={isDark ? THEME.DARK : THEME.LIGHT}
          />
          <ModalUpdateUser />
        </ErrorBoundary>
      </ConfigProvider>
    </ThemeProvider>
  );
});

const StartApp = () => {
  return (
    // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </BrowserRouter>
    // </React.StrictMode>
  );
};

root.render(<StartApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
