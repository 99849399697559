export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const ADMIN_ROUTE = '/admin';
export const PRODUCT = '/product/:id';
export const DASHBOARD_ROUTE = '/dashboard';
export const STORE_ROUTER = '/kho';
export const SVG_ROUTER = '/svg';
export const COMMISSION_ROUTER = '/commission';
export const STAFF_ROUTER = '/staff';
export const CUSTOMER_ROUTER = '/customer';
