import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const WithPermission = ({ children, permission }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const listPermission = userInfo?.listPermission;
    if (
      listPermission?.length &&
      (listPermission?.includes(permission) || listPermission?.includes('administration'))
    )
      setHasPermission(true);
    return () => setHasPermission(false);
  }, [permission, userInfo]);

  return hasPermission ? children : null;
};

export default memo(WithPermission);
