import styled from 'styled-components';

export const AccountInfoStyled = styled.div`
  padding: 35px 15px;
  position: relative;
  width: 1150px;
  border-radius: 10px;
  gap: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--account-info-background);

  .qr-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-upload-wrapper {
      flex: 1;
      height: 100%;
    }
    .ant-upload {
      height: 100%;
    }
  }
  .ant-upload {
    width: 100%;
  }
  .upload-view {
    padding: 10px;
    border-radius: 5px;
    border: 1px dashed #7b8190;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-upload-wrapper {
      height: 80px;
    }
  }
  .qr-content-view {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
    flex: 1;
    padding: 15px 10px !important;
  }

  .upload-qr-text {
    font-size: 10px;
    font-weight: 600;
    color: #7b8190;
    text-transform: uppercase;
  }
  .qr-img {
    height: 100%;
    max-height: 306px;
    width: 100%;
    object-fit: contain;
    padding: 8px;
  }
  .qr-img-employee {
    height: 100%;
    max-height: 345px;
    width: 100%;
    object-fit: contain;
    padding: 8px;
  }
  .upload-view-qr {
    border: 1px solid #cdd5f6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .gender input {
    text-transform: uppercase;
  }
  .ant-input-suffix {
    margin-right: 5px;
  }
  .form-info {
    .ant-spin-nested-loading,
    .ant-spin-container {
      height: 100%;
    }
  }
  .kg {
    color: #5c68f3 !important;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .h-34 {
    height: 34px !important;
  }
  .header-container {
    padding: 0px 20px;
    align-items: center;
  }
  .header-container-isAdmin {
    padding: 0px 20px;
    align-items: center;
    padding-bottom: 10px;
  }
  .header-customer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .header-customer-code {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-transform: uppercase;
    color: var(--account-info-main-text);
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-transform: uppercase;
      color: var(--account-info-main-text-1);
    }
  }
  .header-note-container {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .header-note-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-align: left;
    text-transform: uppercase;
    color: var(--account-info-note-text);
    padding: 10px 0;
    overflow: hidden;
  }
  .header-icon-pencil {
    display: flex;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--account-info-icon-edit);
      }
    }
  }
  .header-customer-detail {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
  .account-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .account-info-isAdmin {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 10px;
  }
  .account-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
  }
  .account-name {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-transform: uppercase;
    color: var(--account-info-name);
  }
  .account-name-isAdmin {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--account-info-name);
  }
  .customer-chat-icon {
    display: flex;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .account-position {
    font-size: 14px;
    font-weight: 600;
    /* line-height: 16.94px; */
    text-transform: uppercase;
    color: var(--account-info-main-text);
    max-width: 300px;
  }
  .close-modal-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 2;
  }
  .level-account {
    position: absolute;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    text-transform: uppercase;
    color: var(--account-info-level-color);
    background-color: var(--account-info-level-background);
    height: 40px;
    width: 222px;
    rotate: 45deg;
    text-align: center;
    top: 40px;
    right: -50px;
    padding: 10px;
  }
  .content-container {
    /* padding: 10px; */
  }
  .account-form {
    flex-direction: column;
    display: flex;
    background-color: var(--acount-form-background);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: var(--account-info-form-shadow);
  }
  .account-form-header {
    background-color: rgba(236, 239, 241, 1);
    padding: 18px 20px;
    /* border-radius: 20px; */
    display: flex;
    align-items: center;
    border-bottom: var(--account-info-form-header-border);
    gap: 20px;
  }
  .account-form-header-icon {
    display: flex;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .account-user-info-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-transform: uppercase;
    color: var(--account-info-main-text);
  }

  .account-form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 10px 0;
  }
  .account-form-content-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .account-form-1 {
    width: 280px;
  }
  .account-form-2 {
    flex: 1;
  }
  .account-input-suffix {
    display: flex;
    svg {
      width: 21px;
      height: 20px;
      path {
        fill: var(--account-input-suffix);
      }
    }
  }
  .ant-input-affix-wrapper,
  .ant-select-selector {
    padding: 0px !important;
    overflow: hidden;
    border-radius: 5px;
  }
  .ant-input-prefix {
    padding: 10px;
    background-color: var(--account-info-prefix-background);
    border-right: var(--account-info-prefix-border-right);
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  span.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper:hover,
  span.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper {
    border-color: var(--input-error-border);
  }
  .ant-form-item-explain {
    display: none;
  }

  .account-input-address-detail {
    border: var(--account-info-form-border);
    box-shadow: unset !important;

    &:hover,
    &:focus {
      box-shadow: unset;
      border: var(--account-info-form-border);
    }
  }
  .ant-input-affix-wrapper,
  .ant-select-selector {
    border: var(--account-info-form-border) !important;
    box-shadow: unset !important;

    &:hover,
    &:focus {
      box-shadow: unset;
      border: var(--account-info-form-border) !important;
    }
  }

  .ant-select {
    &:hover,
    &:focus {
      .ant-select-selector {
        box-shadow: unset !important;
        border: var(--account-info-form-border) !important;
      }
    }
  }
  input,
  .ant-input-affix-wrapper {
    background-color: var(--account-input-background);
  }

  input,
  .account-input-address-detail,
  .ant-select-single,
  .ant-input-prefix {
    height: 37px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: var(--account-info-form-input);
    text-transform: uppercase;
  }
  .account-input-address-detail {
    border-radius: 5px;
    &::placeholder {
      color: var(--account-input-placeholder);
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-transform: uppercase;
    }
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--account-info-form-input);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
  }

  .account-form-save {
    background-color: rgba(255, 84, 26, 1);
    color: #fff;
    padding: 10px 46px;
    border-radius: 10px 0 10px 0;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-transform: uppercase;
    margin-bottom: -1px;
    cursor: pointer;
  }

  .account-service-info {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    gap: 25px;
    background-color: rgba(245, 248, 251, 1);
  }
  .account-service-info-item {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .account-service-info-item-text {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .account-service-info-item-text-14 {
    color: var(--account-text-rates);
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    text-transform: uppercase;
  }

  .account-service-info-item-text-16 {
    color: var(--account-text-rates);
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-transform: uppercase;
  }

  .m3 {
    color: var(--account-text-m3) !important;
  }
  .d {
    color: var(--account-text-dd) !important;
    text-transform: lowercase;
    text-decoration: underline;
  }
  .pt {
    color: var(--account-text-dd) !important;
  }
  .account-help {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ant-switch.ant-switch-checked {
    background: rgba(49, 131, 255, 1) !important;
  }
  .ant-switch-handle {
    top: 5px !important;
    inset-inline-start: 4px !important;
    width: 12px !important;
    height: 12px !important;
  }
  .ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 16px) !important;
  }
  .account-line {
    height: 55px;
    width: 2px;
    background: var(--account-line);
  }
  .account-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .account-private-address {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-transform: uppercase;
    color: #2b2e3b;
    background-color: rgba(236, 239, 241, 1);
    span {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.6px;
      text-transform: uppercase;
    }
    .goc {
      color: #ff541a;
    }
    .user {
      color: #5b73e8;
    }
  }
  .account-private-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 15px;
  }
  .account-private-info-item {
    font-size: 14px;
    font-weight: 500;
    /* line-height: 16.94px; */
    text-transform: uppercase;
    color: var(--account-private-address-color-1);
  }

  .account-private-info-item-1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-transform: uppercase;
    color: var(--account-private-address-color-1);
    /* flex-wrap: wrap; */
    padding: 0 15px;

    &__mobile {
      padding-right: 0;
    }
  }
  .account-private-info-item-view {
    display: flex;
    align-items: center;
    gap: 20px;

    &__mobile {
      gap: 0px;
      justify-content: space-between;
    }
  }
  .no-code {
    color: var(--account-color-no-code) !important;
  }
  .gap-15 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .account-private-copy {
    display: flex;
    align-items: center;
    gap: 10px;
    &__mobile {
      gap: 5px;
    }
  }

  .account-copy-icon {
    display: flex;
  }
  .account-warning-text {
    font-size: 10px;
    text-transform: uppercase;
    color: #ff541a;
  }
  .account-warning-text-1 {
    font-size: 12px;
    text-transform: uppercase;
    color: #ff541a;
  }
  .p-15 {
    padding: 0 15px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .account-copy-text {
    color: var(--account-private-address-color-3);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    line-height: 21px;
  }
  .fs-16 {
    font-size: 16px;
  }
  .account-private-footer {
    border-radius: 0 0 10px 10px;
    background-color: rgba(236, 239, 241, 1);
    color: var(--account-private-address-color-4);
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px;

    span {
      color: var(--account-private-address-color-2);
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .goc {
    color: var(--account-private-address-color-2);
  }
  .account-zalo-scan {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 46px;
    padding: 15px 0;
    background-color: rgba(245, 248, 251, 1);
  }
  .account-zalo-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 34.2px;
    text-align: right;
    text-transform: uppercase;
    color: var(--account-private-address-color-1);
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      text-align: right;
      text-transform: uppercase;
      color: var(--account-private-address-color-3);
    }
  }
  .account-zalo-scan-qr {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    background: #fff;
  }
  .calendar-icon {
    display: flex;
    svg {
      width: 21px;
      height: 15px;
      path {
        fill: var(--account-input-suffix);
      }
    }
  }
  .pointer {
    cursor: pointer;
    input {
      cursor: pointer;
      text-transform: uppercase;
    }
  }
`;
