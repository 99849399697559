import { USER_INFO } from 'constants/localStorage';
import { setStorage } from 'libs/storage';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from 'redux/authSlice';
import { commonActions } from 'redux/commonSlice';
import { userActions } from 'redux/userInfo';
import {
  getUserInfo,
  changePassword,
  insertUpdateUser,
  updateBankInfo,
  uploadImage,
  getUserDepot,
  getUserListDepot,
} from 'services/userService';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* handleGetUserInfo(action) {
  try {
    const [res, resDepot] = yield all([
      call(getUserInfo, action.payload),
      call(getUserDepot, action.payload),
    ]);
    if (!res) return yield put(userActions.getUserInfoFailed());
    const data = {
      ...res.data,
      ...resDepot?.data,
      phone: res?.data?.phone,
      phoneDepot: resDepot?.data.phone,
    };
    yield setStorage(USER_INFO, data);
    yield put(userActions.getUserInfoSuccess(data));
    yield put(commonActions.onCloseLogin());
    yield put(authActions.loginSuccess());
  } catch (error) {
    yield put(userActions.getUserInfoFailed());
  }
}

function* handleInsertUserInfo(action) {
  try {
    const formQrZalo = action?.payload?.formQrZalo;
    const formQrWechat = action?.payload?.formQrWechat;
    const [res, resQrZalo, resQrWechat] = yield all([
      call(insertUpdateUser, {
        ...action.payload,
        filter: undefined,
        formData: undefined,
        formQrZalo: undefined,
        formQrWechat: undefined,
      }),
      formQrZalo && call(uploadImage, formQrZalo),
      formQrWechat && call(uploadImage, formQrWechat),
    ]);
    if (!res || (formQrWechat && !resQrWechat) || (formQrZalo && !resQrZalo))
      return yield put(userActions.insertUserInfoFailed());
    yield put(userActions.getUserInfo());
    toast.success(t('common:success'));
  } catch (error) {
    yield put(userActions.insertUserInfoFailed());
  }
}

function* handleUpdateBankInfo(action) {
  try {
    const res = yield call(updateBankInfo, action.payload);
    if (!res) return yield put(userActions.updateBankInfoFailed());
    yield put(userActions.getUserInfo());
  } catch (error) {
    yield put(userActions.updateBankInfoFailed());
  }
}

function* handleChangePassword(action) {
  try {
    const res = yield call(changePassword, action.payload);
    if (!res) return yield put(userActions.changePasswordFailed());
    yield put(userActions.changePasswordSuccess());
  } catch (error) {
    yield put(userActions.changePasswordFailed());
  }
}

function* uploadAvatar(action) {
  try {
    const res = yield call(uploadImage, action.payload.formData, action.payload.config);
    if (!res) return;
    yield put(userActions.getUserInfo());
    toast.success(t('common:success'));
  } catch (error) {}
}

function* getListUserDepot(action) {
  try {
    const res = yield call(getUserListDepot, action.payload);
    if (!res) return yield put(userActions.getListUserDepotFailed());
    yield put(userActions.getListUserDepotSuccess(res?.data));
  } catch (error) {
    yield put(userActions.getListUserDepotFailed());
  }
}
export default function* userSaga() {
  yield all([takeLatest(userActions.insertUserInfo.type, handleInsertUserInfo)]);
  yield all([takeLatest(userActions.getUserInfo.type, handleGetUserInfo)]);
  yield all([takeLatest(userActions.changePassword.type, handleChangePassword)]);
  yield all([takeLatest(userActions.updateBankInfo.type, handleUpdateBankInfo)]);
  yield all([takeLatest(userActions.uploadAvatar.type, uploadAvatar)]);
  yield all([takeLatest(userActions.getListUserDepot.type, getListUserDepot)]);
}
