import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { permissionActions } from 'redux/permissionSlice';
import {
  getUserPermission,
  updateUserPermission,
  getPositionPermission,
  updatePositionPermission,
} from 'services/permissionService';
import { t } from 'i18next';

function* handleGetUserPermission(action) {
  try {
    const res = yield call(getUserPermission, action.payload);
    if (!res) return yield put(permissionActions.getUserPermissionFailed());
    yield put(permissionActions.getUserPermissionSuccess(res?.data));
  } catch (error) {
    yield put(permissionActions.getUserPermissionFailed());
  }
}

function* handleUpdateUserPermission(action) {
  try {
    const res = yield call(updateUserPermission, { ...action.payload, onClose: undefined });
    if (!res) return yield put(permissionActions.updateUserPermissionFailed());
    const onClose = action?.payload?.onClose;
    if (onClose) {
      toast.success(t('common:success'));
      onClose();
    }
  } catch (error) {
    yield put(permissionActions.updateUserPermissionFailed());
  }
}

function* handleGetPositionPermission(action) {
  try {
    const res = yield call(getPositionPermission, action.payload);
    if (!res) return yield put(permissionActions.getPositionPermissionFailed());
    yield put(permissionActions.getPositionPermissionSuccess(res?.data));
  } catch (error) {
    yield put(permissionActions.getPositionPermissionFailed());
  }
}

function* handleUpdatePositionPermission(action) {
  try {
    const res = yield call(updatePositionPermission, { ...action.payload, onClose: undefined });
    if (!res) return yield put(permissionActions.updatePositionPermissionFailed());
    const onClose = action?.payload?.onClose;
    if (onClose) {
      toast.success(t('common:success'));
      onClose();
    }
  } catch (error) {
    yield put(permissionActions.updatePositionPermissionFailed());
  }
}

export default function* userSaga() {
  yield all([takeLatest(permissionActions.getUserPermission.type, handleGetUserPermission)]);
  yield all([takeLatest(permissionActions.updateUserPermission.type, handleUpdateUserPermission)]);
  yield all([
    takeLatest(permissionActions.getPositionPermission.type, handleGetPositionPermission),
  ]);
  yield all([
    takeLatest(permissionActions.updatePositionPermission.type, handleUpdatePositionPermission),
  ]);
}
