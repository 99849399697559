import Services from './index';

const getUserInfo = async () => await Services.post('/api/User/getUserInfo');
const getUserDepot = async () => await Services.get('/api/User/getUserDepot');
const getUserListDepot = async () => await Services.get('/api/User/listUserDepot');

const insertUpdateUser = async (body) => await Services.post('/api/User/updateUserInfo', body);
const changePassword = async (body) => await Services.post('/api/User/changePassword', body);
const updateBankInfo = async (body) => await Services.post('/api/User/updateBankInfo', body);
const uploadImage = async (body, config) =>
  await Services.post('/api/Image/imageUpload', body, config);

export {
  getUserInfo,
  insertUpdateUser,
  changePassword,
  updateBankInfo,
  uploadImage,
  getUserDepot,
  getUserListDepot,
};
